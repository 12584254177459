import { memo, useContext } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { LoggedInUserContext } from '../../../context/LoggedInUser'

import SignInButton from '../../common/SignInButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const ScrollableContainer = styled.div`
  overflow: auto;
  width: 100%;
`

const Container = styled.div`
  padding: 20px 20px 50vh;
  font-size: 15px;
  max-width: 1000px;
  margin: 0 auto;
  overflow: auto;

  p {
    margin-top: 10px;
  }

  ol, ul {
    padding-inline-start: 20px;
    margin: 10px 0;
  }

  li {
    margin: 5px 0;
  }

  code {
    background: ${({ theme }) => theme.palette.primary.faded}44;
    border: 1px solid ${({ theme }) => theme.palette.primary.faded};
    padding: 4px 4px;
    border-radius: 3px;
    font-size: 12.5px;
    white-space: pre-wrap;
  }

`

const BigIdea = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding: 20px 0;
`

const Questions = styled.div`
  padding-top: 10px;
`

const Heading = styled.div`
  font-weight: 700;
  text-align: left;
  margin: 20px 0 10px;
  font-size: 17px;
`

const ChooseToGetStarted = styled.div`
  font-weight: 500;
  text-align: center;
  margin: 40px 0;
  font-size: 18px;
`

const TranslateInstructions = ({
  setInstructionsRead,
  ...otherProps
}) => {

  const loggedInUser = useContext(LoggedInUserContext)

  return (
    <ScrollableContainer {...otherProps} >
        <Container>

        <BigIdea>
          Would you like to use Biblearc in your own language and allow others to do the same?
        </BigIdea>

        <Heading>
          How It Works
        </Heading>

        <ol>
          <li>
            Open a translation of Biblearc in your language by choosing it above. The translation will default to values received using Google Translate.
          </li>
          <li>
            Browse through the translations, one category at a time, clicking on the ones you want to edit. You are able to edit any translation, except those submitted by an editor or imported. (In that case, you can still suggest an edit.)
          </li>
          <li>
            After you have methodically examined each <b>High Priority</b> category, you can submit it for review. After review, it will be added to the app and marked <i>incomplete</i>.
          </li>
          <li>
            Continue examining the <b>Secondary</b> categories. Afterwards, submit it for review once again. It will be reviewed so that the <i>incomplete</i> flag can be removed.
          </li>
          <li>
            When you discover the need to improve a translation, come back here and make the change. That change will be seen within the app the next time the app is updated. (Typically every 1-2 weeks.)
          </li>
        </ol>

        <Heading>
          Important Notes
        </Heading>

        <ul>
          <li>
            Translations are not available for legacy modules. This currently includes Arcing, Bracketing, Phrasing, and Diagramming. (These modules will be replaced in the future, at which point they will be translatable.)
          </li>
          <li>
            You will often see notation like {`{{`}this{`}}`} when the text includes a swap. Do not translate the text inside a swap. For example, <code>{`{{`}number{`}}`} seconds</code> should be translated to <code>{`{{`}number{`}}`} segundos</code> in Spanish.
          </li>
          <li>
            The whitespace of a translation should match that of the English. (The yellow highlighting allows you to see when there is a space at the beginning or end of the English.)
          </li>
          <li>
            RTL languages (i.e. Arabic, Hebrew, and Persian) are not yet supported. However, you can still translate them as doing so will encourage us to add support in the future.
          </li>
        </ul>

        {[ 'ADMIN', 'EDITOR', 'CONTRIBUTOR', 'TRANSLATOR' ].includes((loggedInUser || {}).adminLevel) &&
          <>

            <Heading>
              Alert: Translation Editors
            </Heading>

            <ul>
              <li>
                You should periodically check the translations of languages you know for suggestions. Do so by changing the <i>Status</i> to <b>Has suggestion</b> and ensuring you are viewing <b>All categories</b> and that the <i>Search</i> is blank.
              </li>
            </ul>

          </>
        }

        <Questions>
          <NavLinkOrAWithDisable to={`https://equip.biblearc.com/contact`}>
            Contact us
          </NavLinkOrAWithDisable>
          {` `}
          with your questions or suggestions.
        </Questions>

        <ChooseToGetStarted>
          {!loggedInUser &&
            <SignInButton
              label="Sign in to get started"
            />
          }
          {!!loggedInUser &&
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => setInstructionsRead(true)}
            >
              Get started
            </Button>
          }
        </ChooseToGetStarted>

      </Container>
    </ScrollableContainer>
  )
}


export default memo(TranslateInstructions)